import React, { useState, useRef } from 'react'
import styled from '@emotion/styled'
import Slide from 'src/components/Slide'
import Section from 'src/components/Section'
import Slider from 'react-slick'
import ArrowLeft from 'src/assets/images/prev-arrow.svg'
import ArrowRight from 'src/assets/images/next-arrow.svg'
import ContentfulRichText from 'src/components/ContentfulRichText'

import { typography, mq, colors, util, animations } from 'src/styles'

const Wrapper = styled(Section)`
  ${ util.responsiveStyles('margin-top', 90, 200, 32, 26) }
  ${ util.responsiveStyles('margin-bottom', 90, 200, 32, 26) }
`

const ArrowControl = styled.button`
  appearance: none;
  width: 50px;
  height: 50px;
  padding: 0;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  bottom: calc(50% - 10px);
  ${ ({ position }) =>
    position === 'left'
      ? `
		left: ${ 100 / 26 }vw;
	`
      : `
		right: ${ 100 / 26 }vw;
	` }
  background: transparent;
  color: ${ colors.mainColor };
  border: none;
  outline: none;
  transition: color ${ animations.mediumSpeed } ease-in-out,
    opacity ${ animations.mediumSpeed } ease-in-out;
  ${ ({ hide }) =>
    hide &&
    `
		// opacity: 0;
		// pointer-events: none;
	` }
  &:hover {
    color: ${ colors.pink2 };
  }
  svg {
    width: 50px;
    height: 50px;
    display: block;
    
    * {
      transition: stroke ${ animations.mediumSpeed } ease-in-out;
      // stroke: currentcolor;
      // stroke-width: 1px;
      // stroke-linecap: butt;
      // stroke-linebutt: butt;
      // stroke-linejoin: miter;
      // fill: ${ colors.mainColor };
    }
    &:hover {
      * {
        stroke: ${ colors.green };
      }
    }
  }
`

const StyledSlider = styled(Slider)`
  padding-bottom: 40px;
  .slick-slide {
    width: 100vw;
    ${ mq.mediumAndBelow } {
      width: 50vw;
    }
  }
`

const SlideshowDots = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
`

const SlideshowDot = styled.li`
  width: 10px;
  height: 10px;
  color: ${ colors.green };
  background: currentcolor;
  cursor: pointer;
  border-radius: 50%;
  margin: 0 5px;
  border: 1px solid currentcolor;
  transform: scale(0.6);
  transition: transform ${ animations.mediumSpeed } ease-in-out,
    background ${ animations.mediumSpeed } ease-in-out;
  .slick-active & {
    transform: none;
    background: transparent;
  }
`

const SlideshowDescription = styled.div`
  margin-top: 25px;
  padding: 0 14vw;
`

const ProjectSlideshow = ({ className, animation, slides, description }) => {
  console.log('slides: ', slides)
  const [activeSlide, setActiveSlide] = useState(0)
  const slideFade = animation === 'fade'
  const customSlider = useRef()

  const NextArrow = ({ onClick }) => (
    <ArrowControl
      position="right"
      onClick={onClick}
      aria-label="Go to next image"
    >
      <ArrowRight />
    </ArrowControl>
  )
  const PrevArrow = ({ onClick }) => (
    <ArrowControl
      position="left"
      onClick={onClick}
      aria-label="Go to previous image"
    >
      <ArrowLeft />
    </ArrowControl>
  )

  function goToNextSlide () {
    customSlider.current.slickNext()
  }

  function goToPrevSlide () {
    customSlider.current.slickPrev()
  }
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    centerMode: true,
    swipe: true,
    fade: slideFade,
    accesibility: true,
    speed: 500, // swipe animation speed
    // variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: dots => <SlideshowDots>{dots}</SlideshowDots>,
    customPaging: (slider, i) => (
      <SlideshowDot active={slider === i}>
        {i}
        {slider.current}
      </SlideshowDot>
    ),
    responsive: [
      {
        breakpoint: 700,
        settings: {
          dots: true,
          arrows: false,
          variableWidth: false,
          centerMode: false,
          dots: true,
          appendDots: dots => <SlideshowDots>{dots}</SlideshowDots>,
          customPaging: (slider, i) => (
            <SlideshowDot active={slider === i}>
              {i}
              {slider.current}
            </SlideshowDot>
          ),
        },
      },
    ],
  }

  return (
    <Wrapper className={className}>
      <StyledSlider {...settings}>
        {slides.map((slide, index) => {
          return (
            <Slide
              key={index}
              title={slide.title}
              image={slide.image}
              description={slide.description}
              vimeoId={slide.vimeoId}
            />
          )
        })}
      </StyledSlider>
      {description ? (
        <SlideshowDescription>
          <ContentfulRichText richText={description.json} />
        </SlideshowDescription>
      ) : (
        false
      )}
    </Wrapper>
  )
}

export default ProjectSlideshow
