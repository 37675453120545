import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'

import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import GeneralContentBlock from 'src/components/GeneralContentBlock'
import { typography, mq, colors, util } from 'src/styles'

import PortalSvg from 'src/assets/images/portal.svg'

const Wrapper = styled.div`
	color: ${ colors.green };
	padding: 110px 0;
`
const HeroWrap = styled.div`
 	svg {
 		width: 100%
 	} 
	h5 {
		margin-top: 3.5rem;
		text-align: center;	
	}
	${ mq.mediumAndBelow } {
		min-height: 20vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		h5 {
			margin-top: 2.5rem;
		}
	}
`

const PageContent = styled(Grid)`
	margin-top: 5rem;
	${ mq.mediumAndBelow } {
		margin-top: 2rem;
	}
`

const InfoSection = styled(Section)`

`

const InfoBlurb = styled(GeneralContentBlock)`
	${ mq.mediumAndBelow } {
	}
`

const ContentWrap = styled.div`


	li {
		list-style: none;
		padding: 10px 0 15px;
		border-bottom: 1px dashed ${ colors.green };
		&:first-of-type {
			border-top: 1px dashed ${ colors.green };
			&:hover {
				border-top: 1px dashed ${ colors.black };
			}
		}
		a {
			&:hover {
				background: ${ colors.green };
				color: ${ colors.black };
			}
		}
		&:hover {
			background: ${ colors.green };
			color: ${ colors.black };
			border-bottom: 1px dashed ${ colors.black };
		}

		${ mq.mediumAndBelow } {
			padding: 17px 0 17px;
		}
	}
`

const ParticipantsWrap = styled.div`
	// padding-bottom: 25px;
	padding: 0 25px 25px;
	border: 1px solid ${ colors.green };
	h3 {
		padding: 0 35px;
	}
	li {
		list-style: none;
		text-transform: uppercase;
		font-family: ${typography.secondaryFont};
		${ util.responsiveStyles('font-size', 20, 20, 20, 16) }

		padding: 8px 35px 8px;
		&:hover {
			background: ${ colors.green };
			color: ${ colors.black };
			a {
				color: ${ colors.black };
			}
		}
	}

	${ mq.mediumAndBelow } {
		margin-bottom: 55px;
		width: 85%;
		margin-left: auto;
		margin-right: auto;
		h3 { 
			padding: 0;
		}
		li {
			padding: 14px 10px;
		}
		padding: 0 20px 10px;
	}
`

const ResourceGroup = styled.div`
	margin-top: 5rem;
	${ mq.mediumAndBelow } {
		width: 85%;
		margin-left: auto;
		margin-right: auto;
	}
`

const ResourceTitle = styled.p`
	padding-left: 10px;
	margin: 0;
	${ mq.mediumAndBelow } {
		padding-left: 0;
	}
`

const PortalIndexGroup = ({
		className,
		year,
		participants,
		info,
		resourceGroup
	}) => {

	const host = process.env.HOST || process.env.GATSBY_HOST

	const { portalPng} = useStaticQuery(
		graphql`
			query {
				portalPng: file(relativePath:{eq: "images/portal.png"}) {
					publicURL
				}
			}
		`)

	return (
			<Wrapper className={className}>
		  	<Grid
		  		small="[12]"
		  		medium="6 [12] 6"
		  		vAlign="top"
	  		>			
		  		<HeroWrap>
			  		<PortalSvg/>
				  	<h5>* Vis-a-thon {year}, SPRING</h5>
			  	</HeroWrap>
		  	</Grid>
	  	
	  	<InfoSection>
	  	<PageContent
	  		small="[12]"
	  		medium="[10] 2 [10]"
	  		vAlign="top"
  		>
  			<ParticipantsWrap>
	  			<h3>*{year}* Participants</h3>
	  			<ul>
				  	{participants.map((participant, index) => {
				  		return(
					  			<li>
						  			<a href={participant.gDriveLink} target="_blank">
						  				<span>{participant.firstName} {participant.lastName}</span>
						  			</a>
									</li>
				  		)
				  	})}
	  			</ul>
		  	</ParticipantsWrap>
		  	<ContentWrap>
			  	<InfoBlurb
						title={info.title}
						subtitle={info.subtitle}
						text={info.text}
						cta={info.cta}
						twoUp={true}
					/>

					{resourceGroup.map((group, index) => {
			  		return(
			  			<ResourceGroup>
			  			<h3>{group.title}</h3>
				  			<ul>
				  			 {group.resourceGroup.map((resource, index) => {
				  			 		return (
				  			 			<li>
				  			 				<a href={resource.link} target="_blank">
				  			 					<ResourceTitle>
					  			 					{resource.title}
				  			 					</ResourceTitle>
				  			 				</a>
				  			 			</li>
			  			 			)
				  			 }) }
				  			</ul>
			  			</ResourceGroup>
			  		)
			  	})}
		  	</ContentWrap>
			</PageContent>
			</InfoSection>
	  </Wrapper>
	)
}
export default PortalIndexGroup
