import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'

import * as util from 'src/styles/util'
import { colors, typography, animations } from 'src/styles'
import MaterialIcon from 'src/components/MaterialIcon'

import Link from 'src/components/Link'

const StyledLink = styled(Link)`
	${ typography.h6 }
	position: relative;
	display: inline-block;
	border: 1px dashed ${ colors.white };

	padding: 1em 2em;
	&:hover {
		// &:after {
		// 	transform-origin: left center;
		// 	transform: scaleX(1);
		// }
		// background: ${ colors.black };
		border: 1px dashed ${ colors.green};
		color: ${ colors.green };
	}

	${ ({ disabled }) => disabled && `
		pointer-events: none;
		cursor: not-allowed;
	` }
`

class TextLink extends Component {
	render () {
		const {
			to,
			external,
			target,
			loading,
			error,
			success,
			disabled,
			onClick,
			theme,
			className,
			children,
			title
		} = this.props

		return (
			<StyledLink
				className={'button ' + className}
				to={to}
				target={target}
				external={external}
				loading={loading}
				error={error}
				success={success}
				disabled={disabled}
				onClick={onClick}
				theme={theme}
				title={title}
			>
				{children}
			</StyledLink>
		)
	}
}

TextLink.defaultProps = {
	theme: 'currentcolor'
}

export default TextLink
