import React from 'react'
// import TextSection from 'src/components/TextSection'
// import Columns from 'src/components/Columns'
// import FiftyFifty from 'src/components/FiftyFifty'
// import EventList from 'src/components/EventList'
import WideMedia from 'src/components/WideMedia'
import BlockText from 'src/components/BlockText'
import FullMedia from 'src/components/FullMedia'
import GeneralContentBlock from 'src/components/GeneralContentBlock'
import Accordion from 'src/components/Accordion'
import TwoUp from 'src/components/TwoUp'
import ImageGroup from 'src/components/ImageGroup'
import ProjectList from 'src/components/ProjectList'
import PortalIndexGroup from 'src/components/PortalIndexGroup'
// import LandingLogos from 'src/components/LandingLogos'
import SoundPlayer from 'src/components/SoundPlayer'
import ProjectSlideshow from 'src/components/ProjectSlideshow'

const componentMap = {
  // ContentfulTextSection: TextSection,
  // ContentfulColumns: Columns,
  // ContentfulFiftyFifty: FiftyFifty,
  // ContentfulEventList: EventList,
  // ContentfulLandingBlock: LandingLogos,
  ContentfulWideMedia: WideMedia,
  ContentfulBlockText: BlockText,
  ContentfulFullMedia: FullMedia,
  ContentfulGeneralContentBlock: GeneralContentBlock,
  ContentfulAccordion: Accordion,
  ContentfulTwoUp: TwoUp,
  ContentfulImageGroup: ImageGroup,
  ContentfulProjectIndexList: ProjectList,
  ContentfulPortalIndexGroup: PortalIndexGroup,
  ContentfulSoundPlayer: SoundPlayer,
  ContentfulProjectSlideshow: ProjectSlideshow,
}

export default ({
  item,
  prevTheme,
  nextTheme,
  index,
  isLastSection,
  isFirstSection,
}) => {
  const Component = componentMap[item.__typename]
  return Component ? (
    <Component
      {...item}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      isLastSection={isLastSection}
      isFirstSection={isFirstSection}
      index={index}
    />
  ) : null
}
