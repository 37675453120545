import React from 'react'
import styled from '@emotion/styled'
import Grid from 'src/components/Grid'
import Link from 'src/components/Link'
import { typography, mq, colors, util } from 'src/styles'

const Wrapper = styled.div`
	width: 66%;
	margin-left: auto;
	margin-bottom: 10vh;
	${ mq.mediumAndBelow } {
		width: 100%;
		h2 {
			text-align: center;
			margin-top: 5rem;
		}
	}
`

const StyledListItem = styled.li`
	list-style-type: none;
	width: 100%;
	padding-top: 8px;
	padding-bottom: 5px;
	border-bottom: 1px dashed ${ colors.white };
	&:hover {
		color: ${ colors.green }
	}
	padding: 12px 0;
	${ mq.mediumAndBelow } {
		padding: 17px 0;
	}
`

const StyledLink = styled(Link)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	font-size: 20px;
`

const Author = styled.span`
	text-transform: uppercase;
	${ typography.h6 }
	font-size: 18px !important;
`

const Title = styled.span`
	
`

const ProjectList = ({ className,  projects, projectYear}) => {
	return (
	  <Wrapper className={className}>
	  	<h2>Vis-a-thon {projectYear}</h2>
	  	<ul>
	  	{projects.map((project, index) => {
	  		return (
	  			<StyledListItem>
		  			<StyledLink
							key={'button-' + index}
							to={'/'+ project.slug}
							external={false}
							title={project.projectTitle}
						>
							<Grid
					  		small="[4] 2 [6]"
					  		vAlign="middle"
							>
			  			<Author>{project.author}</Author> 
			  			<Title>{project.projectTitle}</Title>
			  			</Grid>
			  		</StyledLink>
		  		</StyledListItem>
				)

	  	})}
	  	</ul>
		</Wrapper>
	)
}

export default ProjectList
