import React from 'react'
import styled from '@emotion/styled'
import Button from 'src/components/Button'
import TextLink from 'src/components/TextLink'
import Section from 'src/components/Section'
import ScrollEntrance from 'src/components/ScrollEntrance'
import ContentfulRichText from 'src/components/ContentfulRichText'
import { typography, mq, colors, util } from 'src/styles'

const Wrapper = styled.div`
	vertical-align: top;
	display: flex;
	// padding-top: 95px;
	${ mq.mediumAndBelow } {
		display: flex;
	}
	${ ({ textSize }) => textSize === 'lg' ? `
		min-height: 100vh;
		// padding: 95px 0;
		justify-content: center;
		align-items: center;
	` : `
		justify-content: flex-start;
		align-items: center;
	`}

	${ mq.mediumAndBelow } {
		flex-direction: column;
		// min-height: unset;
	}
`

const BlockTextSection = styled(Section)``


const TextContainer = styled.div`
	text-align: ${ ({ alignment }) => alignment };
	
	${ ({ alignment }) => alignment === 'center' && `
		margin-left: auto;
		margin-right: auto;
	` }
	${ ({ alignment }) => alignment === 'right' && `
		margin-left: auto;
	` }

	${ ({ textSize }) => textSize === 'lg' ? `
		max-width: 65%;
	` : `
		max-width: 85%;
	`}

	${ mq.mediumAndBelow } {
		max-width: 95%;
	}
	h6 {
		color: ${ colors.green };
	}
`

const Text = styled.div`
	p {
		${ ({ textSize }) => typography[textSize] }
		margin-bottom: 0;
		&:first-of-type {
			margin-top: 0;
		}
		${ ({ alignment }) => alignment === 'center' && `
			margin-left: auto;
			margin-right: auto;
		` }
		${ ({ alignment }) => alignment === 'right' && `
			margin-left: auto;
		` }

		b {
			color: ${ colors.green };
		}

		${ mq.mediumAndBelow } {
			&:not(:first-of-type) {
				margin-top: 3.5rem;
			}
		}
	}
`

const textSizeConvert = {
	sm: 'bodySmall',
	md: 'bodyMedium',
	lg: 'bodyLarge'
}

const BlockText = ({
		textSize,
		textAlignment,
		textPosition,
		text
	}) => {
	return (
			<Wrapper textSize={textSize} textPosition={textPosition}>
				<div>
					<TextContainer alignment={textAlignment} textSize={textSize} >
						{text && text.json &&
							<Text alignment={textAlignment} textSize={textSizeConvert[textSize]}><ContentfulRichText richText={text.json}/></Text>
						}
					</TextContainer>
				</div>
			</Wrapper>
	)
}

export default BlockText