import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Image from 'src/components/Image'
import Img from 'gatsby-image'
import Video from 'src/components/Video'
import Grid from 'src/components/Grid'
import ReactPlayer from 'react-player'
import { typography, mq, colors, util } from 'src/styles'
import * as globals from 'src/styles/gridSettings'
import ContentfulRichText from 'src/components/ContentfulRichText'

const Wrapper = styled(Section)`
	margin-bottom: 110px;
	${ mq.mediumAndBelow } {
		margin-bottom: 2.5rem;
	}
`

const VideoWrapper = styled.div`
	position: relative;
	padding-bottom: ${ 9/16 * 100 }%;
	background-color: ${colors.black};
`

const StyledVideo = styled(ReactPlayer)`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;
	z-index: 1;
`

const WideMediaWrap = styled.div`
  > div {
    max-height: 100%;
  }
  ${ mq.mediumAndBelow } {
		margin-left: -20px;
		// margin-right: ${ globals.containerLargeMargins };
		display: block;
		// width: 100%;
		width: calc(100% + 20px * 2);
	}
`

const ContentWrap = styled.div`
  position: relative;
`

const InfoWrapper = styled.div`
	padding: 0 80px;
	${ mq.mediumAndBelow } {
		padding: 0;
	}	
`

const NativeCaptionWrap = styled.div`
	text-align: center;
`

const MediaResponsiveImage = styled(Image)`
 
`
const MediaImage = styled(Img)`
 
`
const FullMedia = ({
		className, 
		mediaType, 
		image, 
		videoId,
		isFirstSection,
		title,
		description,
		nativeCaption
	}) => {
	// console.log("title", title)
	// console.log("description", description)
	if (!image && !videoId) {
    return false
  }

  if ( mediaType === 'video' ) {
  	return (
  		<Wrapper isFirstSection={isFirstSection}>
  		<VideoWrapper>
	  		<StyledVideo
					url={'https://vimeo.com/' + videoId}
					playing={false}
					loop={false}
					muted={false}
					autoPlay={false}
					playIcon={<span />}
					light={false}
					controls={true}
					config={{
						vimeo: {
              color: 'white',
              playsinline: true
            }
					}}
				/>
			</VideoWrapper>
			<InfoWrapper>
				{ title && (
					<h3>{title}</h3>
				)}
				{ description && (
					<ContentfulRichText richText={description.json}/>
				)}
			</InfoWrapper>
			</Wrapper>
		)
  } else {
  	return (
		  <Wrapper className={className} isFirstSection={isFirstSection}>
		  	<WideMediaWrap>
				  <Grid small='[1]'>
				    <ContentWrap>
				      {image ? (
				        <MediaResponsiveImage
				          image={image.image}
				          small={image.small}
				          medium={image.medium}
				          setHeight='auto'
				          fadeIn={!isFirstSection}
				          loading={isFirstSection ? 'eager' : 'lazy'}
				          sizes='100vw'
				        />
				      ) : false }
				      
				    </ContentWrap>
				  </Grid>
				</WideMediaWrap>
				<InfoWrapper>
					{ title && nativeCaption && (
						<NativeCaptionWrap>
							<ContentfulRichText richText={title.json}/>
						</NativeCaptionWrap>
					)}
					{ title && !nativeCaption && (
						<h3>{title}</h3>
					)}
					{ description && (
						<ContentfulRichText richText={description.json}/>
					)}
				</InfoWrapper>
		  </Wrapper>
		)
  }
}

export default FullMedia
