import React from 'react'
import styled from '@emotion/styled'
import AnimateHeight from 'react-animate-height'
import ContentfulRichText from 'src/components/ContentfulRichText'
import Collapse from 'src/components/Collapse'
import { typography, mq, colors, util } from 'src/styles'


const Wrapper = styled.div`
	${ ({ width }) => width === 'narrow' ? `
		width: 66%;
	` : `
		width: 100%;
	`}
	${ ({ alignment }) => alignment === 'center' && `
		margin-left: auto;
		margin-right: auto;
	` }
	${ ({ alignment }) => alignment === 'right' && `
		margin-left: auto;
	` }

	${ mq.mediumAndBelow } {
		width: 100%;
		&:not(:first-of-type) {
			margin-bottom: 110px;
		}
	}

	&:not(:first-of-type) {
		margin-top: 6.5em;
	}
	h2 {
		margin: 1em 0;
	}

	

	p {
		a {
			border-color: rgba(0,0,0,0);	
			&:hover {
				border-color: ${ colors.green };
			}
			${ mq.mediumAndBelow } {
				border-color: ${ colors.green };	
				margin-bottom: 10px;		
			}
		}
	}	
`


const Accordion = ({ className, sectionTitle, items, width, alignment }) => {
	if ( items ){
		return (
			<Wrapper width={width} alignment={alignment}>
				<h2>{sectionTitle}</h2>

			{items.map((item, index) => {
				return (
					<Collapse
						collapsed={true}
						title={item.label}
						className={'accordion-'+sectionTitle}
						key={index}
					>
						<ContentfulRichText richText={item.content.json}/>
					</Collapse>
				)
			})}	
	  	
			</Wrapper>
		)
	}
}

export default Accordion
