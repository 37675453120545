import React from 'react'
import styled from '@emotion/styled'
import Button from 'src/components/Button'
import TextLink from 'src/components/TextLink'
import Section from 'src/components/Section'
import ScrollEntrance from 'src/components/ScrollEntrance'
import ContentfulRichText from 'src/components/ContentfulRichText'
import { typography, mq, colors, util } from 'src/styles'

// const Wrapper = styled(Section)`
const Wrapper = styled.div`
	display: inline-block;
	display: block;
	vertical-align: top;
	${ mq.mediumAndBelow } {
		display: block;
	}
`


const TextContainer = styled.div`
	text-align: left;
	${ ({ width }) => width === 'narrow' ? `
		width: 66%;
	` : `
		width: 100%;
	`}
	${ ({ alignment }) => alignment === 'center' && `
		margin-left: auto;
		margin-right: auto;
	` }
	${ ({ alignment }) => alignment === 'right' && `
		margin-left: auto;
	` }
	${ mq.mediumAndBelow } {
		width: 100%;
	}

	h6 {
		${ util.responsiveStyles('margin-top', 24, 16, 16, 8) }
	}
`

const Text = styled.div`
	p {
		${ util.responsiveStyles('font-size', 20, 20, 18, 16) }
		margin-bottom: 0;
		&:first-of-type {
			margin-top: 0;
		}
		${ ({ alignment }) => alignment === 'center' && `
			margin-left: auto;
			margin-right: auto;
		` }
		${ ({ alignment }) => alignment === 'right' && `
			margin-left: auto;
		` }

		b {
			color: ${ colors.green };
		}
	}
`

const ActionWrapper = styled.div`
	padding: 16px 10px 0;
	display: inline-block;
	vertical-align: middle;
`

const ButtonActions = styled.div`
	margin-top: 2.5em;
	text-align: ${ ({ alignment }) => alignment };
	margin-left: -10px;
	margin-right: -10px;
`

const GeneralContentBlock = ({
		title,
		subtitle,
		text,
		cta,
		width,
		alignment,
		twoUp
	}) => {
	return (
		<Wrapper halved={twoUp}>
			<div>
				<TextContainer width={width} alignment={alignment}>
					{title && (
						<h1>{title}</h1>
					)}

					{subtitle && (
						<h2>{subtitle}</h2>
					)}

					{text && text.json &&
						<Text><ContentfulRichText richText={text.json}/></Text>
					}

					{cta && (
						<ButtonActions buttons={cta}>
							{cta.map((action, index) => {
								console.log('action:', action.to)
									return (
										<ActionWrapper key={index}>
											<TextLink
												key={'button-' + index}
												to={action.to || '/'}
												external={action.to}
												target={action.openInNewTab ? '_blank' : ''}
												title={action.label}
												disabled={ action.to != null ? false : true }
											>
												{action.label}
											</TextLink>
										</ActionWrapper>
									)
							})}
						</ButtonActions>
					)}
				</TextContainer>
			</div>
		</Wrapper>
	)
}

export default GeneralContentBlock