import React from 'react'
import styled from '@emotion/styled'
import ReactAudioPlayer from 'react-audio-player';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';

import SoundPlay from 'src/assets/images/sound-play.svg';
import SoundPause from 'src/assets/images/sound-stop.svg';

import Section from 'src/components/Section'
import { typography, mq, colors, util } from 'src/styles'

const Audio = styled(AudioPlayer)`
	&.rhap_container {
		background-color: transparent;
		box-shadow: none;
		width: 100%;
		display: block;
		padding-left: 0;
		padding-right: 0;
		max-width: 50px;
		.rhap_main {
			&.rhap_horizontal {
				display: block;
				.rhap_controls-section {
						display: block;
						margin-left: 0 !important;

					.rhap_main-controls {
						display: block;
						.rhap_play-pause-button{
							width: 65%;
							height: 65%;
							svg {
								width: 70%;
							}
						}
						.rhap_volume-controls {
							display: none;
						}
					}
				}	
			}
		}
	}
`

const AudioRow = styled.div`
	display: flex;
	justify-content: space-between;
	border-bottom: 1px dashed white;
`

const AudioWrapper = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	// min-width: 50%;
`

const AudioTitle = styled.p`
	font-size: 18px;
	flex-grow: 1;
	flex-shrink: 1;
	padding-top: 7px;
	min-width: 75%;
	line-height: 1em;
`

const Wrapper = styled(Section)`
	width: 50%;
	margin: 0 auto;
	${ mq.mediumAndBelow } {
		width: 75%;
	}	

`

const SoundPlayer = ({ className, title, sounds }) => { 
	console.log('SoundPlayer :::', SoundPlay)
	return (
  	<Wrapper className={className}>
  		<h3>{title}</h3>
  		{ sounds.map((sound, index) => {
  			return (
  				<AudioRow>
		  				<Audio
			  				layout="horizontal"
		  					src={'http:'+sound.media.file.url}
							  showFilledProgress={false}
							  showJumpControls={false}
							  showDownloadProgress={false}
							  customProgressBarSection={[]}
							  customAdditionalControls={[]}
							  customVolumeControls={[]}
							  customIcons={{
								  play: <SoundPlay/>,
								  pause: <SoundPause/>
								}}
		  				/>
	  				<AudioTitle>{sound.title}</AudioTitle>
  				</AudioRow>
				)
  		})}
		</Wrapper>
	)
}

export default SoundPlayer
