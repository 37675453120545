import React, { Fragment, useState, useCallback } from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Img from 'gatsby-image'
import Image from 'src/components/Image'
import ContentfulRichText from 'src/components/ContentfulRichText'
import ScrollEntrance from 'src/components/ScrollEntrance'
import { typography, mq, colors, util } from 'src/styles'

const ImageGroupSection = styled(Section)`
	${ util.responsiveStyles('margin-top', 90, 200, 32, 26) }
	${ util.responsiveStyles('margin-bottom', 90, 200, 32, 26) }
	// background: red;
	// border: 5px solid dodgerblue;
`

const Wrapper = styled.div`
	// padding-left: 10vw;
	// padding-right: 10vw;
	height: 100%;
	${ ({ configuration }) => configuration === "stacked" ? `
			min-height: 70vh;
			position: static;
			display: flex;
			flex-direction: column;

	` : configuration === "layered" ? `
			// background: red;
			position: relative;
			width: 100%;
			// margin: 5vh 0;
	` : ``}

	${ ({ imgCount }) => imgCount === 1 ? `
		// single styles
		// background: salmon;

		min-height: 40vh;
		${ mq.mediumAndBelow } {
			min-height: unset;
		}	


	` : imgCount === 2 ?  `
		// double styles
		// background: dodgerblue;

		position: relative;
		height: 60vw;
		padding: 5vh 0;

	` : imgCount === 3 ?  `
		// triple styles
		
		// background: chartreuse;
		// height: 120vh;
		height: 50vw;
	` : imgCount === 4 ? `
		// fours styles
		
		// background: dodgerblue;
		height: 75vw;

	` : ``}
`

const ImageWrapper = styled.div`

	${ ({ imgCount, aspectRatio, index, captions, configuration }) => configuration === "stacked" ? `
		flex-grow: 0;
		flex-shrink: 0;
	` : `

	`}


	${ ({ imgCount, aspectRatio, index, captions, configuration }) => imgCount === 1 ? `
		// single styles

		${ aspectRatio < 1 ? `width: 30%;` : `width: 55%;`}
		margin: 0 auto;
    // transform: translateY(calc(55% * ${aspectRatio} / 2));
		${ configuration === 'layered' ? `

		` : ``}

	` : imgCount === 2 ?  `
		// double styles

			${ configuration === 'layered' ? `
				position: absolute;
				${ index === 0 ? `
					// first image

						${ aspectRatio < 1 ? `width: 30%;` : `width: 40%;`}
						top: 0;
						left: 8%;
				` : `
					// second image

						${ aspectRatio < 1 ? `width: 30%;` : `width: 60%;`}
						top: 30%;
						right: 10%;
				`}
			` : ``}
			
	` : imgCount === 3 ?  `
		// triple styles
		// border: 3px ${ colors.green } dotted;

			${ configuration === 'layered' ? `

				position: absolute;
				${ index === 0 ? `
					// first image

						${ aspectRatio < 1 ? `width: 31%;` : `width: 24%;`}
						top: 0;
						left: 13%;
						z-index: 1;

				` : index === 1 ? `
					// second image
						${ aspectRatio < 1 ? `width: 45%;` : `width: 45%;`}
						top: 15vw;
						// bottom: calc(40vh / ${aspectRatio} * 0.5);
						right: 28%;
						z-index: 2;

				` : index === 2 ? `
						${ aspectRatio < 1 ? `width: 25%;` : `width: 32%;`}
						top: 30vw;
						right: 3%;
						z-index: 1;
				` : ``}
			` : `

			// configuration = stacked
				&:not(:first-of-type){
					margin-top: 50px;
				}
					margin-bottom: 50px;
				${ index === 0 ? `
					// first image

						${ aspectRatio < 1 ? `width: 28%;` : `width: 24%;`}

				` : index === 1 ? `
					// second image
						${ aspectRatio < 1 ? `width: 45%;` : `width: 45%;`}

						margin-left: auto;
						margin-right: auto;

				` : index === 2 ? `
						${ aspectRatio < 1 ? `width: 25%;` : `width: 32%;`}
						margin-left: 68%;
				` : ``}

			`}


	` : imgCount === 4 ? `
		// fours styles

			${ configuration === 'layered' ? `
				position: absolute;
				${ index === 0 ? `
					// first image
						// border: 3px chartreuse dotted;

						
						width: 24%;

						top: 0;
						left: 13%;
						z-index: 1;

				` : index === 1 ? `
					// second image
						// border: 3px dodgerblue dotted;

						
						width: 45%;
						top: 25vw;
						// bottom: calc(100vw / ${aspectRatio} * 0.5);
						right: 23%;
						z-index: 2;

				` : index === 2 ? `
					// third image
						// border: 3px yellow dotted;
						
						width: 37%;

						top: 10%;
						left: 50%;
						z-index: 4;
				` : index === 3 ? `
					// fourth image 
						// border: 3px salmon dotted;
						
						width: 19%;

						top: 40vw;
						right: 20%;
						z-index: 3;
				` : ``}
			` : ``}
	` : ``}




	// flex-grow: 1;
	// ${ ({ aspectRatio }) => aspectRatio < 1 ? `
	// 	min-width: 30%;
	// ` : `
	// 	min-width: 45%;
	// `}

	${ ({ zIndex }) => zIndex === true ? `
		// border: 3px solid ${ colors.green };

		box-shadow: 20px 30px 50px -20px rgba(0,0,0,0.88);
		-webkit-box-shadow: 20px 30px 50px -20px rgba(0,0,0,0.88);
		-moz-box-shadow: 20px 30px 50px -20px rgba(0,0,0,0.88);

		z-index: 2;
	` : `
		z-index: 1;
	`}

	


	// &:first-of-type {
	// 	${ ({ captions }) => captions === true ? `
	// 		> div {
	// 			max-width: 50%;
	// 			margin-right: 20px;
	// 		}
	// 		display: flex;
	// 		justify-content: space-between;
	// 	` : `
	// 		max-width: 50%;
	// 	`}

	// 	${ ({ configuration }) => configuration === 'stacked' ? `

	// 		${ util.responsiveStyles('padding-top', 91, 75, 33, 26) }
	// 		${ util.responsiveStyles('padding-bottom', 91, 75, 33, 26) }
	// 	` : configuration === 'layered' ? `
	// 			position: absolute;
	// 			top: 0;
	// 			left: 0;
	// 			background: chartreuse;
	// 			z-index: 1;
	// 	` : ``}
	// }

	// &:nth-of-type(2):not(:last-of-type) {
		

	// 	${ ({ configuration }) => configuration === 'stacked' ? `
	// 		${ ({ captions }) => captions === true ? `
	// 			> div {
	// 				width: 76% !important;
	// 				&:last-of-type {
	// 					${ util.responsiveStyles('margin-top', 26, 18, 18, 10) }
	// 				}
	// 			}
	// 			margin-left: 12%;
	// 		` : `
	// 			max-width: 76%;
	// 			margin-left: 12%;
	// 		`}
	// 	` : configuration === 'layered' ? `
	// 			position: absolute;
	// 			top: 20%;
	// 			right: 0;
	// 			background: plum;
	// 			z-index: 2;
	// 	` : ``}
		
	// }

	// &:last-of-type {
		

	// 	${ ({ configuration }) => configuration === 'stacked' ? `
	// 		> div {
	// 			max-width: 70%;
	// 			&:last-of-type {
	// 				${ util.responsiveStyles('margin-top', 26, 18, 18, 10) }
	// 			}
	// 		}
	// 		margin-left: 30%;
	// 	` : configuration === 'layered' ? `
			
			

	// 		background: salmon;
	// 		position: absolute;
	// 		left: 25%;
	// 		top: 75%;
	// 		z-index: 3;
	// 		min-width: 45%;
	// 	` : ``}	
	// }

`

const ImageOnlyWrap = styled.div`
	
`

const CaptionWrapper = styled.div`
`

const CaptionTitleWrap = styled.div`
	
`

const GroupCaptionWrap = styled.div`
	// background: plum;
	// text-align: center;
	width: 55%;
	margin-top: 50px;
	// margin: 0 auto;
	${ mq.mediumAndBelow } {
		width: 100%;		
		margin-top: 20px;
	}	
`

const ImageGroup = ({ className, showCaptions, configuration, images, groupCaption }) => {
	// console.log('imagegroup:::', showCaptions, configuration)
	const [ zIndexActive, toggleZindex ] = useState(1)
	// console.log('zIndexActive:::', zIndexActive)
	const imgCount = images.length
	// console.log('imgCount:::', imgCount)


	return (
		<ImageGroupSection className={"image-group " + configuration} >
  		<Wrapper className={className} configuration={configuration} imgCount={imgCount}>
  			{images.map((image,index) => {
  				// console.log('image aspectRatio: ',image.image.fluid.aspectRatio)
  				return (
  					<ImageWrapper
  						index={index}
  						captions={showCaptions}
  						configuration={configuration}
  						imgCount={imgCount}
  						zIndex={index === zIndexActive ? true : false}
  						aspectRatio={image.image.fluid.aspectRatio}
  						onClick={() => toggleZindex(index)}
  						key={index}
  					>	
	  					<Image
	              image={image.image}
	              small={image.small}
	              medium={image.medium}
	              setHeight='auto'
	              sizes='100vw'
	              className='image'
	            />
	            
            </ImageWrapper>
					)
  			})}
			</Wrapper>
			{ groupCaption && (
				<GroupCaptionWrap>
					<ContentfulRichText richText={groupCaption.json}/>
				</GroupCaptionWrap>
			)}
		</ImageGroupSection>
	)
}

export default ImageGroup
