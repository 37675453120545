import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import GeneralContentBlock from 'src/components/GeneralContentBlock'
import Img from 'gatsby-image'
import { typography, mq, colors, util } from 'src/styles'


const Wrapper = styled(Section)`
	${ util.responsiveStyles('margin-bottom', 200, 200, 150, 150) }
`

const ColumnWrapper = styled.div`
	&:not(:last-of-type) {
		${ mq.mediumAndBelow } {
			padding-bottom: 110px;
		}
	}
`

const SectionTitle = styled.h1`
	${ ({ isFirstSection }) => isFirstSection === true ? `
		margin-top: 0;
	` : ``}
	
	${ mq.mediumAndBelow } {
			padding-bottom: 2.25rem;
		}
`

const TwoUp = ({ className, isFirstSection, sectionTitle, left, right }) => {
	return (
  <Wrapper className={className} isFirstSection={isFirstSection}>

  	<SectionTitle isFirstSection={isFirstSection}>{sectionTitle}</SectionTitle>

  	<Grid
  		small="[24]"
  		large='[11] 2 [11]'
  		vAlign="top"
		>
			<ColumnWrapper>
				<GeneralContentBlock
					title={left.title}
					subtitle={left.subtitle}
					text={left.text}
					cta={left.cta}
					twoUp={true}
				/>
			</ColumnWrapper>

			<ColumnWrapper>
				<GeneralContentBlock
					title={right.title}
					subtitle={right.subtitle}
					text={right.text}
					cta={right.cta}
				/>
			</ColumnWrapper>			

		</Grid>
  </Wrapper>
)}

export default TwoUp
