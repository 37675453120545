import React, { useState } from 'react'
import styled from '@emotion/styled'
import { typography, mq, colors, util } from 'src/styles'
import AnimateHeight from 'react-animate-height'

const CollapseWrapper = styled.div`
  width: 100%;
  text-align: left;
  transition: margin 1s ease-in-out;
  &.open {
  	margin-bottom: 50px;
  }
`

const UnstyledButton = styled.button`
	display: block;
	appearance: none;
	line-height: unset;
  outline: none;
  width: 100%;
  border: none;
  background: inherit;
  font-size: inherit;
  text-decoration: inherit;
  font-weight: inherit;
  padding: 0;
  margin: 0;
  border-radius: unset;
  box-shadow: unset;
  background: unset;
  color: inherit;
  height: unset;
  cursor: pointer;
  transition: none;
`

const CollapseHeader = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	&:hover {
		color: ${ colors.green };
	}
	
`

const DisplayName = styled.div`
	width: 100%;
  p {
  	text-align: left;
  	margin: 0;
  	padding: 0.75em 0;
  	${ util.responsiveStyles('font-size', 20, 20, 18, 16) }
  	
  }
  transition: border 0.65s ease;
  border-bottom: 1px dashed ${ colors.white };

  ${ ({ open }) => open === true ? `
	  border-bottom: 1px dashed transparent;
		p {
			color: ${ colors.green };
		}
		${ mq.mediumAndBelow } {
			p {
			}
		}	
	` : `
  	border-bottom: 1px dashed ${ colors.white };
	`}	
`

const CollapsedContent = styled(AnimateHeight)`
	overflow: hidden;
	// ${ util.responsiveStyles('font-size', 20, 20, 18, 16) }

	&.open {
		// padding: 0.75em 0 1em;
		border-bottom: 1px dashed ${ colors.white };	

		p {
			color: ${ colors.green };

			&:last-of-type {
				margin-bottom: 20px;
			}
		}

		border-bottom: none;
	}
`

const DetailsContainer = styled.div``

const Collapse = ({ collapsed, children, title, className }) => {
  const [ open, toggleOpen ] = useState(!collapsed)
	if (children) {
		return (
			<CollapseWrapper className={open ? className + ' open CollapseWrapper' : className}>
				{title && (
					<UnstyledButton onClick={() => toggleOpen(!open)} open={open} className={open ? 'title open' : 'title'}>
						<CollapseHeader>
							<DisplayName hasChildren={true} open={open}>
								<p>{title}</p>
							</DisplayName>
						</CollapseHeader>
					</UnstyledButton>
				)}
				<CollapsedContent
					className={open ? 'content open' : 'content'}
					duration={400}
					delay={0}
					animateOpacity={true}
					height={!open ? 0 : 'auto'}
					open={open}
				>
					<DetailsContainer>
						{children}
					</DetailsContainer>
				</CollapsedContent>
			</CollapseWrapper>
		)
	} else {
		return (
			<CollapseWrapper className={className}>
				<CollapseHeader>
					<DisplayName>
						<div>{title}</div>
					</DisplayName>
				</CollapseHeader>
			</CollapseWrapper>
		)
	}
}

Collapse.defaultProps = {
	index: null,
	collapsed: true
}

export default Collapse
